.box-container-header {
  padding: 10px;
  background-color: rgba(21, 26, 48, 0.5);
  border-radius: 5px;
  box-shadow: rgb(193 191 191 / 24%) 0px 3px 8px;
}

.custom-tooltip {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Warna putih dengan opacity 0.8 */
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.box-container {
  padding: 15px;
  transition: all 0.5s ease;
}

.button-group {
  display: flex;
}
.button-group button{
  margin-left: 5px;
}

@media (max-width: 600px) {
  .box-container {
    padding: 10px;
    transition: all 0.5s ease;
  }
}

.rt-thead {
  position: sticky;
  top: 0;
  z-index: 2;
  background: whitesmoke;
  color: black;
}
.rt-tbody {
  overflow: hidden !important;
  height: 500px;
  color: black;
  background-color: whitesmoke;
}
.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #e3e3e3;
}

.header-button {
  display: flex;
  justify-content: flex-end;
}

.-striped .rt-tr.-odd {
  background-color: white;
}

.react-table {
  tr.-striped:nth-child(odd) {
    background-color: white;
  }
}

.box-floating-container {
  width: 100%;
  margin: 0 auto;
}

.box-floating {
  width: 50px;
  height: 50px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto";
}

/* In your CSS file, e.g., App.css or index.css */
@font-face {
  font-family: "Roboto";
  src: url("../src//fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* @media (max-width: 600px) {
  .small-screen {
    font-size: 1rem;
  }
} */

.card-content {
  text-align: center;
}

.total-value {
  font-size: 1.5rem;
}

.label-info-balance-equity {
  display: flex;
  flex-direction: row;
  /* font-size: 1rem; */
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  flex-wrap: nowrap;
}

.label-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
}

.equity-label {
  font-size: 1rem;
}

.equity-value {
  font-size: 1rem;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .total-value {
    font-size: 1rem;
  }

  .label-info {
    display: block;
    margin-top: 10px;
    font-size: 0.8rem;
  }

  .label-info-balance-equity {
    display: block;
    margin-top: 10px;
    /* font-size: 0.5rem; */
  }

  .equity-label,
  .equity-value {
    display: block; /* Tampilkan dalam dua baris */
    text-align: center; /* Pusatkan teks */
    font-size: 0.8rem;
  }

  .equity-label {
    margin-bottom: 5px; /* Berikan sedikit jarak antara baris */
  }
}
